import { TTimelineObject } from ".";
import { generateError } from "..";
import * as timeline from "../data/timeline.json";

export class Timeline {
  private moduleName: string;

  constructor() {
    this.moduleName = "Timeline";
  }

  async getTimeline(): Promise<TTimelineObject[]> {
    try {
      return Promise.resolve(timeline as any);
    } catch (e) {
      return Promise.reject(
        generateError(
          new Error(e),
          this.moduleName + " unavailable. Please retry."
        )
      );
    }
  }
}
