import { TFilter, TPens, TPensObject } from ".";
import { generateError } from "../Utils";
import * as creations from "../data/creations.json";

export class Pens {
  private moduleName: string;

  constructor() {
    this.moduleName = "Pens";
  }

  async getPens(filters?: TFilter[]): Promise<TPensObject> {
    try {
      const datas = creations as any;
      let pens = datas.items as TPens[];
      if (filters !== undefined && filters !== null && filters?.length > 0) {
        pens = pens.reduce((acc: TPens[], cur: TPens) => {
          let canBePushed = false;
          filters.forEach((f: TFilter) => {
            if (cur.filters.indexOf(f.name) !== -1) {
              canBePushed = true;
            }
          });
          if (canBePushed) {
            acc.push(cur);
          }
          return acc;
        }, []);
      }
      const dataPens: TPensObject = {
        baseUrl: datas.thumbnail.base_url,
        pens: pens,
      };
      return Promise.resolve(dataPens);
    } catch (e) {
      return Promise.reject(
        generateError(
          new Error(e),
          this.moduleName + " unavailable. Please retry."
        )
      );
    }
  }

  async getFilters(): Promise<TFilter[]> {
    try {
      const datas = creations as any;
      let pens = datas.items as TPens[];
      let filters = pens.reduce((acc: TFilter[], cur: TPens) => {
        cur.filters.forEach((element) => {
          const indexElement = acc.map((f) => f.name).indexOf(element);
          if (indexElement === -1) {
            acc.push({
              name: element,
              occurrence: 1,
            });
          } else {
            acc[indexElement].occurrence += 1;
          }
        });
        return acc;
      }, []);
      return Promise.resolve(filters);
    } catch (e) {
      return Promise.reject(
        generateError(
          new Error(e),
          this.moduleName + " - Filters unavailable. Please retry."
        )
      );
    }
  }
}
