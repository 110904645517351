import { generateError } from "../Utils";
import { TCertifications } from "./types";
import * as certifications from "../data/certifications.json";

export class Certifications {
  private moduleName: string;

  constructor() {
    this.moduleName = "Certifications";
  }

  async getCertifications(): Promise<TCertifications> {
    try {
      return Promise.resolve(certifications);
    } catch (e) {
      return Promise.reject(
        generateError(
          new Error(e),
          this.moduleName + " unavailable. Please retry."
        )
      );
    }
  }
}
