import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

i18n
  .use(HttpBackend) // Utiliser le backend HTTP pour charger les fichiers JSON
  .use(initReactI18next)
  .init({
    fallbackLng: "fr", // Langue par défaut
    lng: "fr", // Langue initiale
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", // Chemin pour charger les fichiers JSON
    },
    interpolation: {
      escapeValue: false, // React s'occupe déjà de l'échappement
    },
  });

export default i18n;
