import Experiences from "./Components/Experiences/Experiences";
import Profile from "./Components/Profile/Profile";
import "./Home.css";

export default function Home() {
  return (
    <ul
      className="Home"
      style={{
        display: "flex",
        margin: "auto",
        height: "100vh",
      }}
    >
      <li
        className="HiddenScrollbar"
        style={{
          padding: "100px 15px",
          overflow: "auto",
          maxHeight: "100%",
        }}
      >
        <Profile />
      </li>
      <li
        className="CV HiddenScrollbar"
        style={{
          padding: "15px",
          overflow: "auto",
          maxHeight: "100%",
        }}
      >
        <Experiences />
      </li>
    </ul>
  );
}
