import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Tag.css";
import { faJava, faReact } from "@fortawesome/free-brands-svg-icons";
import {
  SiAmazonec2,
  SiAmazons3,
  SiAngular,
  SiAntdesign,
  SiApachecordova,
  SiApifox,
  SiContentful,
  SiCss3,
  SiDatabricks,
  SiFigma,
  SiGraphql,
  SiHtml5,
  SiJavascript,
  SiJenkins,
  SiMysql,
  SiNextdotjs,
  SiNodedotjs,
  SiPhp,
  SiQt,
  SiRedis,
  SiRedux,
  SiShieldsdotio,
  SiSocketdotio,
  SiSwift,
  SiSymfony,
  SiTypescript,
  SiVisualstudiocode,
} from "react-icons/si";
import { useEffect, useRef } from "react";
import { faLink } from "@fortawesome/free-solid-svg-icons";

export type TagIconType =
  | "react"
  | "css"
  | "html"
  | "javascript"
  | "typescript"
  | "nodejs"
  | "swift"
  | "php"
  | "nextjs"
  | "graphql"
  | "link"
  | "vscode"
  | "contentful"
  | "java"
  | "cordova"
  | "angular"
  | "symfony"
  | "redux"
  | "amazons3"
  | "amazonec2"
  | "jenkins"
  | "rest"
  | "qml"
  | "realm"
  | "dp3t"
  | "mysql"
  | "socket"
  | "figma"
  | "redis"
  | "antd";

type Props = {
  icon?: TagIconType;
  text: string;
};

export default function Tag({ icon, text }: Props) {
  const ref = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    const tagRef = ref.current;
    if (tagRef === undefined || tagRef === null) return;
    function handleMouseMove(e: any) {
      const card = e.target;
      const { left, top } = card.getBoundingClientRect();
      card.style.setProperty("--x", `${e.clientX - left}px`);
      card.style.setProperty("--y", `${e.clientY - top}px`);
    }
    tagRef.addEventListener("mousemove", handleMouseMove);
    return () => {
      tagRef.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div style={{ display: "inline-block", verticalAlign: "middle" }}>
      <ul ref={ref} className="Tag">
        {icon && (
          <li style={{ marginRight: 8 }}>
            {icon === "react" && (
              <FontAwesomeIcon icon={faReact} color="#61DAFB" />
            )}
            {icon === "typescript" && <SiTypescript color="#3178C6" />}
            {icon === "swift" && <SiSwift color="#FA7343" />}
            {icon === "nodejs" && <SiNodedotjs color="#339933" />}
            {icon === "php" && <SiPhp color="#777BB4" />}
            {icon === "nextjs" && <SiNextdotjs color="#000000" />}
            {icon === "graphql" && <SiGraphql color="#E10098" />}
            {icon === "css" && <SiCss3 color="#1572B6" />}
            {icon === "html" && <SiHtml5 color="#E34F26" />}
            {icon === "javascript" && <SiJavascript color="#F7DF1E" />}
            {icon === "link" && (
              <FontAwesomeIcon icon={faLink} color="#a561d3" />
            )}
            {icon === "vscode" && <SiVisualstudiocode color="#007ACC" />}
            {icon === "contentful" && <SiContentful color="#2478CC" />}
            {icon === "java" && (
              <FontAwesomeIcon icon={faJava} color="#007396" />
            )}
            {icon === "cordova" && <SiApachecordova color="#E8E8E8" />}
            {icon === "angular" && <SiAngular color="#DD0031" />}
            {icon === "symfony" && <SiSymfony color="#000000" />}
            {icon === "redux" && <SiRedux color="#764ABC" />}
            {icon === "amazons3" && <SiAmazons3 color="#569A31" />}
            {icon === "amazonec2" && <SiAmazonec2 color="#FF9900" />}
            {icon === "jenkins" && <SiJenkins color="#D24939" />}
            {icon === "qml" && <SiQt color="#41CD52" />}
            {icon === "rest" && <SiApifox color="#E13C3E" />}
            {icon === "realm" && <SiDatabricks color="#4DB33D" />}
            {icon === "dp3t" && <SiShieldsdotio color="#00A1F1" />}
            {icon === "mysql" && <SiMysql color="#4479A1" />}
            {icon === "socket" && <SiSocketdotio color="#010101" />}
            {icon === "figma" && <SiFigma color="#F24E1E" />}
            {icon === "redis" && <SiRedis color="#DC382D" />}
            {icon === "antd" && <SiAntdesign color="#0170FE" />}
          </li>
        )}
        <li style={{ fontSize: "0.9em" }}>{text}</li>
      </ul>
    </div>
  );
}
