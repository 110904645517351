import { generateError } from "..";
import { TCvObject } from "./types";
import * as cv from "../data/cv.json";
import * as competences from "../data/competences.json";
import * as certifications from "../data/certifications.json";
import * as timeline from "../data/timeline.json";

export class CV {
  private moduleName: string;

  constructor() {
    this.moduleName = "Cv";
  }

  async getCV(): Promise<TCvObject> {
    try {
      return Promise.resolve(this.getCvData());
    } catch (e) {
      return Promise.reject(
        generateError(
          new Error(e),
          this.moduleName + " unavailable. Please retry."
        )
      );
    }
  }

  private async getCvData(): Promise<any> {
    try {
      let cvData = cv;
      let skillsData = competences as any;
      let certificationsData = certifications as any;
      let timelineData = timeline;
      const contentFormations: any = [];
      const contentExperiences: any = [];
      timelineData.map((content) => {
        const { type } = content.contenu;
        if (type === "entreprise") {
          contentExperiences.push(content);
        } else if (type === "ecole") {
          contentFormations.push(content);
        } else {
          throw "Unrecognized type: " + type;
        }
      });
      cvData.formations = contentFormations;
      cvData.competences = skillsData;
      cvData.experiences = contentExperiences;
      cvData.certifications = certificationsData;
      return Promise.resolve(cvData);
    } catch (e) {
      return Promise.reject(
        generateError(
          new Error(e),
          this.moduleName + " unavailable. Please retry."
        )
      );
    }
  }
}
