import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TTimelineObject } from "yb-bridge";
import { capitalize } from "../../../../Utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faImage } from "@fortawesome/free-solid-svg-icons";
import Tag, { TagIconType } from "../../../../Atomic/Tag/Tag";

type Props = {
  datas: TTimelineObject[];
};

type ExperienceImageProps = {
  src: string;
  alt: string;
};

function ExperienceImage({ src, alt }: ExperienceImageProps) {
  const [imageError, setImageError] = useState(false);

  return (
    <>
      {!imageError ? (
        <img
          className="SchoolLogo"
          src={src}
          alt={alt}
          onError={() => setImageError(true)}
        />
      ) : (
        <div className="ImagePlaceholder">
          <FontAwesomeIcon icon={faImage} color="#ccc" />
        </div>
      )}
    </>
  );
}

export default function ExperienceBlock({ datas }: Props) {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState<"fr-FR" | "en-US">("fr-FR");

  useEffect(() => {
    setLang(i18n.language === "fr" ? "fr-FR" : "en-US");
  }, [i18n]);
  return (
    <ul>
      {datas.map((experience, i) => {
        const { contenu, datezone } = experience;
        const { experiences: missions, logo, titre, description } = contenu;
        return (
          <li key={i}>
            <ul
              className="ExperienceDateBlock"
              style={{ display: "flex", padding: "15px 0" }}
            >
              <li>
                <label className="SchoolDate">{datezone.annee[lang]}</label>
              </li>
              <li>
                <ul>
                  <li>
                    <ul style={{ display: "flex" }}>
                      <li>
                        <ExperienceImage alt="logo" src={logo} />
                      </li>
                      <li className="SchoolDescription">
                        <label>{titre[lang]}</label>
                        <br />
                        <span style={{ transform: "translateY(-10px)" }}>
                          {datezone.ville}
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>{description[lang]}</p>
                  </li>
                  {missions && (
                    <li style={{ color: "lightgray" }}>
                      <label>{t("missions")}:</label>
                      <ul className="ExperiencesMissions">
                        {missions.map((mission, i) => {
                          const {
                            entreprise,
                            periode,
                            projet,
                            realisations,
                            environnement_technique,
                          } = mission;
                          return (
                            <li key={i}>
                              <h4>{capitalize(entreprise)}</h4>
                              <b className="MissionPeriode">
                                {periode.debut[lang]} - {periode.fin[lang]}
                              </b>
                              <p style={{ marginTop: 10 }}>{projet[lang]}</p>
                              <ul
                                style={{ marginTop: 10 }}
                                className="MissionRealisation"
                              >
                                {realisations.map((realisation, i) => {
                                  const iconSize = 20;
                                  return (
                                    <li key={i} style={{ marginBottom: 3 }}>
                                      <ul
                                        style={{
                                          display: "flex",
                                          alignItems: "flex-start",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <li
                                          style={{
                                            width: iconSize,
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            className="MissionArrow"
                                            size={"xs"}
                                            icon={faAngleRight}
                                          />
                                        </li>
                                        <li
                                          style={{
                                            width:
                                              "calc(100% - " + iconSize + "px)",
                                          }}
                                        >
                                          <p>{realisation[lang]}</p>
                                        </li>
                                      </ul>
                                    </li>
                                  );
                                })}
                              </ul>
                              <ul
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  marginTop: 10,
                                }}
                              >
                                {environnement_technique.map((techno, i) => {
                                  return (
                                    <li key={i}>
                                      <Tag
                                        text={capitalize(techno)}
                                        icon={techno as TagIconType}
                                      />
                                    </li>
                                  );
                                })}
                              </ul>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </li>
        );
      })}
    </ul>
  );
}
