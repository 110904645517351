import { TLocalizedContent } from "../Utils";

export type TTimelineExperiences = {
    entreprise: string;
    titre: TLocalizedContent;
    periode: {
        debut: TLocalizedContent;
        fin: TLocalizedContent;
    };
    projet: TLocalizedContent;
    realisations: TLocalizedContent[];
    environnement_technique: string[];
}

export enum TTimelineContentType {
    Entreprise = 'entreprise',
    Ecole = 'ecole'
}

export type TTimelineContent = {
    type: TTimelineContentType;
    titre: TLocalizedContent;
    poste: TLocalizedContent;
    description: TLocalizedContent;
    logo: string;
    missions: TLocalizedContent[];
    experiences: TTimelineExperiences[];
}

export type TTimelineDateZone = {
    ville: string;
    annee: TLocalizedContent;
}

export type TTimelineObject = {
    contenu: TTimelineContent;
    font_icon: string;
    datezone: TTimelineDateZone;
}