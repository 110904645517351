import { useTranslation } from "react-i18next";
import Panel from "../../../../Components/Panel/Panel";
import "./Profile.css";
import Tag, { TagIconType } from "../../../../Atomic/Tag/Tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faArrowRight,
  faCode,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faTiktok,
  faYoutube,
  faCodepen,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { capitalize } from "../../../../Utils/utils";

function Presentation() {
  const { t } = useTranslation();

  const prezKey = "presentation";
  const prez = t(prezKey);
  if (prezKey === prez) return null;
  const splitted = prez.split("#");
  return (
    <>
      {splitted[0]}
      <Tag icon={"react"} text="React" />
      {splitted[1]}
      <Tag icon="typescript" text={"Typescript"} />
      <br />
      {splitted[2]}
      <Tag icon="nodejs" text={"Node"} />
      {splitted[3]}
      <Tag icon="php" text={"PHP"} />
      <br />
      {splitted[4]}
      <Tag icon="swift" text={"Swift"} />
    </>
  );
}

export default function Profile() {
  const { t } = useTranslation();

  return (
    <Panel className="ProfilePanel">
      <ul>
        <li style={{ display: "flex" }}>
          <Panel className="ProfileImage">
            <img src="https://images.yoannbraie.fr/avatar.png" alt="Profile" />
          </Panel>
          <Panel className="ProfileDescription">
            <p style={{ transform: "translateY(15px)" }}>{t("bonjour")}</p>
            <p
              className="SecondaryFont"
              style={{
                fontSize: "4em",
                fontWeight: "bold",
                color: "transparent",
                WebkitTextStroke: "1px #a561d3",
              }}
            >
              Yoann Braie
            </p>
          </Panel>
        </li>
        <li>
          <Panel className="ProfilePresentation">
            <h3>{t("frontend")}</h3>
            <ul className="PresentationImportant">
              {Array.from({ length: 3 }).map((_, index) => {
                const indexKey = index + 1;
                return (
                  <li key={index}>
                    <FontAwesomeIcon icon={faAngleRight} />
                    <span>{t(`point${indexKey}`)}</span>
                  </li>
                );
              })}
            </ul>
            <h3>{t("technos")}</h3>
            <ul
              style={{
                display: "flex",
                marginLeft: -5,
                marginTop: 20,
                marginBottom: 20,
                flexWrap: "wrap",
              }}
            >
              {[
                "react",
                "nextjs",
                "typescript",
                "html",
                "css",
                "javascript",
                "graphql",
                "nodejs",
                "php",
                "swift",
              ].map((t, i) => {
                return (
                  <li key={i}>
                    <Tag text={capitalize(t)} icon={t as TagIconType} />
                  </li>
                );
              })}
            </ul>
            <h3>{t("reseaux")}</h3>
            <ul className="PresentationLinks">
              {[
                {
                  color: "#0A66C2",
                  link: "https://www.linkedin.com/in/yoann-braie/",
                  logo: faLinkedin,
                  title: "linkedin",
                },
                {
                  color: "#080808",
                  link: "https://www.tiktok.com/@c.eairen?lang=fr",
                  logo: faTiktok,
                  title: "tiktok",
                },
                {
                  color: "#FF0000",
                  link: "https://www.youtube.com/@ceairen/shorts",
                  logo: faYoutube,
                  title: "youtube",
                },
                {
                  color: "#000000",
                  link: "https://codepen.io/yoann-b",
                  logo: faCodepen,
                  title: "codepen",
                },
                {
                  color: "#8957E5",
                  link: "https://github.com/ceairen",
                  logo: faGithub,
                  title: "github",
                },
                {
                  color: "#FE575B",
                  link: "https://www.malt.fr/profile/yoannbraie",
                  logo: faCode,
                  title: "malt",
                },
              ].map((e, i) => {
                const { logo, title, link, color } = e;
                return (
                  <li key={i}>
                    <a href={link} target="__blank">
                      <FontAwesomeIcon icon={logo} color={color} />
                      <span>{capitalize(title)}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </Panel>
        </li>
      </ul>
    </Panel>
  );
}
