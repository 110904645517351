import { useEffect, useRef } from "react";
import "./Layout.css";
import { useTranslation } from "react-i18next";

type Props = {
  children: React.ReactNode;
};

export default function Layout({ children }: Props) {
  const mainRef = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (mainRef.current) {
        const rect = mainRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        mainRef.current.style.setProperty("--mouse-x", `${x}px`);
        mainRef.current.style.setProperty("--mouse-y", `${y}px`);
      }
    };

    const mainElement = mainRef.current;
    if (mainElement) {
      mainElement.addEventListener("mousemove", handleMouseMove);
    }

    // Cleanup pour retirer l'événement lorsque le composant est démonté
    return () => {
      if (mainElement) {
        mainElement.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, []);

  return (
    <main ref={mainRef}>
      {children}
      {/* <button onClick={() => i18n.changeLanguage("fr")}>
        Change to French
      </button>
      <button onClick={() => i18n.changeLanguage("en")}>
        Change to English
      </button>*/}
    </main>
  );
}
