import { TLang, TLocalizedContent, YBError } from "./types";

export const API_URL = "https://apiv3.yoannbraie.fr/yb";
export const FILE_URL = "https://file.yoannbraie.fr";
export const MAILER_URL = "https://mailer.yoannbraie.fr";

export const generateError = (e: Error, m: string): YBError => {
  return {
    error: e,
    userMessage: m,
  };
};

export const translateLocalized = (
  lang: TLang,
  localized: TLocalizedContent | string
) => {
  if (typeof localized === "string") {
    return localized;
  }
  return localized[lang];
};
