import { FILE_URL, generateError } from "..";
import { TTutos, TTutosCategory } from "./types";
import * as tutosData from "../data/tutos.json";

export class Tutos {
  private fileUrl: string;
  private moduleName: string;

  constructor() {
    this.fileUrl = `${FILE_URL}`;
    this.moduleName = "Tutos";
  }

  async getTutoContent(tutoLink: string): Promise<string> {
    try {
      const response = await window.fetch(this.fileUrl + "/" + tutoLink);
      const datas = (await response.text()) as string;
      return Promise.resolve(datas);
    } catch (e) {
      return Promise.reject(
        generateError(
          new Error(e),
          this.moduleName + " unavailable. Please retry."
        )
      );
    }
  }

  async getTuto(id: number): Promise<TTutos> {
    try {
      const datas = tutosData as TTutos[];
      const tutos = datas.filter((t) => t.id === id);
      if (tutos.length !== 1) {
        return Promise.reject(
          generateError(
            new Error(),
            "L'id de ce tutoriel est inconnu. Merci de réessayer."
          )
        );
      }
      return Promise.resolve(tutos[0]);
    } catch (e) {
      return Promise.reject(
        generateError(
          new Error(e),
          this.moduleName + " unavailable. Please retry."
        )
      );
    }
  }

  async getCategories(): Promise<TTutosCategory[]> {
    try {
      const datas = tutosData as TTutos[];
      const categories = datas.reduce((p: TTutosCategory[], c: TTutos) => {
        let indexOfCategory = p.map((p) => p.category).indexOf(c.category);
        if (indexOfCategory === -1) {
          p.push({
            category: c.category,
            tutos: [c],
          });
        } else {
          p[indexOfCategory].tutos = [...p[indexOfCategory].tutos, c];
        }
        return p;
      }, []);
      return Promise.resolve(categories);
    } catch (e) {
      return Promise.reject(
        generateError(
          new Error(e),
          this.moduleName + " unavailable. Please retry."
        )
      );
    }
  }
}
