type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
};

export default function Panel({ children, style, className }: Props) {
  return (
    <div style={style} className={`Panel ${className ? className : ""}`}>
      {children}
    </div>
  );
}
