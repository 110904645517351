import { useEffect, useState } from "react";
import "./Experiences.css";
import { CV, TCvObject } from "yb-bridge";
import Loading from "../../../../Atomic/Loading/Loading";
import { useTranslation } from "react-i18next";
import Tag from "../../../../Atomic/Tag/Tag";
import ExperienceBlock from "./ExperienceBlock";

export default function Experiences() {
  const { t } = useTranslation();

  const [cv, setCv] = useState<TCvObject | null>(null);

  async function fetchCv() {
    try {
      const cv = await new CV().getCV();
      setCv(cv);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchCv();
  }, []);

  return cv === null ? (
    <Loading />
  ) : (
    <ul>
      <li>
        <h3>{t("certifications")}</h3>
        <ul>
          {cv.certifications.map((certif, i) => {
            const { title, url } = certif;
            return (
              <li key={i}>
                {title}
                <br />
                <a href={url} target="__blank">
                  <Tag icon="link" text={url}></Tag>
                </a>
              </li>
            );
          })}
        </ul>
      </li>
      <li>
        <h3>{t("formations")}</h3>
        <ul>
          <ExperienceBlock datas={cv.formations} />
        </ul>
      </li>
      <li>
        <h3>{t("experiences")}</h3>
        <ExperienceBlock datas={cv.experiences} />
      </li>
    </ul>
  );
}
