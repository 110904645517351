import { generateError } from "../Utils";
import { TCompetenceObject } from "./types";
import * as skills from "../data/competences.json";

export class Skills {
  private url: string;
  private moduleName: string;

  constructor() {
    this.moduleName = "Skills";
  }

  async getSkills(): Promise<TCompetenceObject[]> {
    try {
      return Promise.resolve(skills as any);
    } catch (e) {
      return Promise.reject(
        generateError(
          new Error(e),
          this.moduleName + " unavailable. Please retry."
        )
      );
    }
  }
}
