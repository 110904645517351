import { generateError, MAILER_URL } from "..";
import { TMailer } from "./types";

export class Mailer {
    private url: string;
    private moduleName: string;

    constructor() {
        this.url = `${MAILER_URL}`;
        this.moduleName = 'Mailer';
    }

    async sendMail(mailerData: TMailer): Promise<boolean> {
        try {
            await fetch(this.url, {
                method: "POST",
                headers: {
                    "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
                },
                body: Object.entries(mailerData).map(([k,v])=>{return k+'='+v}).join('&'),
              });
            return Promise.resolve(true);
        } catch (e) {
            return Promise.reject(
                generateError(
                    new Error(e), 
                    this.moduleName + ' unavailable. Please retry.'
                )
            );
        }
    }
}
